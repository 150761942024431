import { MouseEvent, useCallback, useState } from "react";
import { atom, useRecoilCallback, useRecoilState } from "recoil";

type EventCallback = (...args:any[]) => any;

const eventsState = atom<Array<{ [key:string]: EventCallback; }>>({
    key: "eventsState",
    default: []
})

export function useEventsState(){

    const [ events, setEventsState ] = useRecoilState(eventsState)

    const add = useCallback((key:string, callback:EventCallback) => {
        setEventsState(state => ([ ...state, { [key]: callback } ]))
        return () => remove(key, callback)
    }, [ events ])

    const remove = useCallback((key:string, callback:EventCallback) => {
        setEventsState(state => {
            return state.filter(event => {
                if(!event[key]) return true
                if(event[key] !== callback) return true
            })
        })
    }, [ events ])

    const trigger = useRecoilCallback(({ snapshot }) => async (key:string, ...args:any[]) => {
        const events = await snapshot.getPromise(eventsState)
        events.forEach(event => event[key] && event[key](...args))
    }, [])

    const handler = useCallback((key:string) => {
        return (event:MouseEvent<HTMLElement>) => {
            console.log("trigger-from-handler", key, events)
            event.preventDefault()
            trigger(key)
        }
    }, [ trigger ])

    return {
        add,
        remove,
        trigger,
        handler,
    }

}