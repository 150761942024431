import { useParams } from "react-router-dom"
import { useAppState } from "../state/app/useAppState"

export function SeasonStatsPage(){
    
    const app = useAppState()
    const { seasonId } = useParams()
    
    return (<>
        <iframe src={`${app.config.apps.statsWidget}/seasons/${seasonId}/standings?configuration[logo]=false`} />
    </>)
}