import { useEffect } from "react"
import { useAppState } from "../../state/app/useAppState"
import { Logo } from "@gamesheet/ui"

import ScheduleIcon from '@mui/icons-material/DateRange';
import RosterIcon from '@mui/icons-material/Groups';
import StatsIcon from '@mui/icons-material/Scoreboard';
import MoreIcon from '@mui/icons-material/MoreHoriz'

import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useReadTeamsData } from "../../state/data/useTeams";
import { useReadTeamEventsData } from "../../state/data/useTeamEvents";
import { useReadTeamRosterData } from "../../state/data/useTeamRoster";
import { LoadingLayout } from "../../layouts/LoadingLayout";
import { MoreMenu } from "./Menu";
import { useEventsState } from "../../state/app/useEventsStats";

const DesktopIconBar = styled.div`
height: 32px;
background-color: ${props => props.theme.primaryColor};
padding: 4px;
position: fixed;
left: 0;
top: 0;
right: 0;
z-index: 400;

div {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    button {
        display: block;
        text-decoration: none;
        color: ${props => props.theme.textOnPrimaryColor};
        background-color: transparent;
        border: none;
    }
}
`

const StyledLogo = styled(Logo).attrs<{
    className?: string
}>({
    className: "logo"
})`
height: 24px;
margin: 5px;
padding: 5px;
`

export function TeamsApp(){
    
    const app = useAppState()
    const events = useEventsState()

    useReadTeamsData()
    useReadTeamEventsData()
    useReadTeamRosterData()

    useEffect(() => {
        app.menu.close()
    }, [])
    
    useEffect(() => {

        app.navigation.set([
            { to: "/games", icon: <ScheduleIcon />, label: "Schedule", mobile: "left", desktop: true },
            { to: "/roster", icon: <RosterIcon />, label: "Roster", mobile: "left", desktop: true},
            { to: "/games", mobile: "icon"},
            { to: "/seasons", icon: <StatsIcon />, label: "Seasons", mobile: "right", desktop: true},
            { action: events.handler('toggle-more-menu'), icon: <MoreIcon />, label: "More", mobile: "right" },
        ])

    }, [])

    if( !app.loading.isComplete('teams') ){
        return <LoadingLayout />
    }

    return <>

        {app.layout.showDesktop && <>
        <DesktopIconBar>
            <div>
                <StyledLogo color="black" contrast="lightbg" logo="horizontal" size="h6" />
                <button onClick={events.handler('toggle-more-menu')}>{app.activeUser?.email}</button>
            </div>
        </DesktopIconBar>
        </>}

        <Outlet />
        
        <MoreMenu />
        
    </>
}