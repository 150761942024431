import { useMemo } from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

export const progressHints = [
  "Stickhandling",
  "Driving the Net",
  "Taping Sticks",
  "Picking Corners",
  "Cleaning the Ice",
  "Drop Pass",
  "Top Cheddar",
  "Warming the Bench",
  "Dropping the Gloves",
  "Stacking the Pads",
  "Bar Down",
  "Cherrypicking",
  "Going Coast to Coast",
  "Dirty Danglin'",
  "Watching Highlights",
  "Dipsy Doodlin'",
  "Putting the Little Brother in Net"
];

export const StyledProgressBar = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;

	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}

	.title,
	.status {
		margin-bottom: 0.5rem;
	}

	.title {
		margin-right: auto;
		font-weight: normal;
	}

	.status {
		flex-shrink: 0;

		.symbol {
			display: inline-block;
		}

		.pending,
		.requested {
			color: ${({theme}) => theme.secondaryColor};
		}

		.success {
			color: ${({theme}) => theme.success};

			.symbol {
				transform: rotate(45deg) translateY(-2px);
				margin-right: 0.75em;
			}
		}

		.failure {
			color: ${({theme}) => theme.danger};

			.symbol {
				transform: scale(1.2);
				margin-right: 0.5em;
			}
		}
	}

	.graph {
		width: 100%;
		height: 2rem;
		display: flex;
		border-radius: 4px;
		overflow: hidden;

		&.pending,
		&.requested {
			background-color: ${({theme}) => theme.muted};
		}

		&.success {
			background-color: ${({theme}) => theme.success};
		}

		.item {
			width: 100%;
			height: inherit;

			&.pending {
				order: 4;
			}

			&.requested {
				order: 3;
				background-color: rgba(${({theme}) => theme.success}, 0.25);
			}

			&.success {
				order: 1;
				background-color: ${({theme}) => theme.success};
			}

			&.failed {
				order: 2;
				background-color: ${({theme}) => theme.danger};
			}
		}
	}
`;

type ProgressBarProps = {
    // title text shown on the left of the progress bar
    title: string;
  
    // this sets the entire progress state, but is overridden in segments by the progress failures
    state?: "pending" | "requested" | "success" | "failed";
  
    // progress numbers
    total: number;
    pending: number;
    requested: number;
    success: number;
    failed: number;
  }


export function ProgressBar({ title, total, state, pending, requested, success, failed }: ProgressBarProps) {
  const progressStatus = useMemo(
    () => {
      switch (true) {
        // eslint-disable-next-line no-fallthrough
        default:
        case !!pending || total > 0:
          return `${progressHints[Math.floor(Math.random() * progressHints.length)]}...`;

        case total === success + failed && total > 0:
          return "C'est la Butt!";
      }
    },
    [success, failed, total, pending]
  );

  return (
    <StyledProgressBar>
      <h5 className="title">{title}</h5>
      <p className="status">{progressStatus}</p>
      <div className={`graph ${state}`}>
        {pending > 0 &&
          Array(pending)
            .fill(0)
            .map((x, i) => <div className={`item pending`} key={i} />)}
        {requested > 0 &&
          Array(requested)
            .fill(0)
            .map((x, i) => <div className={`item requested`} key={i} />)}
        {success > 0 &&
          Array(success)
            .fill(0)
            .map((x, i) => <div className={`item success`} key={i} />)}
        {failed > 0 &&
          Array(failed)
            .fill(0)
            .map((x, i) => <div className={`item failed`} key={i} />)}
      </div>
    </StyledProgressBar>
  );
}