import { Title } from "@gamesheet/ui";
import { Roster } from "../views/Roster";
import * as Grid from "../layouts/GridItems";

export function SeasonRosterPage(){
    
    const roster = { coaches: [], skaters: [], goalies: []}

    return <Roster roster={roster} />

}