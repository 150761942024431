import { useAppState } from "../state/app/useAppState";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export function LeagueAppHomePage(){

    const app = useAppState()
    const params = useParams()
    
    // this is setup so that the path is only changed on first load which stops the iframe from reloading
    // and allowing the internal iframe to handle the routing
    const path = useMemo(() => params['*'] || "", [])

    return (<>
        <iframe src={`${app.config.apps.leagueDashboard}/${path}`} />
    </>)
    
}