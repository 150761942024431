import { Button, FormWrapper } from "@gamesheet/ui";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { ActionBar } from "../components/ActionBar";
import IframeWithOtp from "../components/IframeWithOtp";
import { useAppState } from "../state/app/useAppState";
import { useStatsWidget } from "../state/app/useStatsWidget";
import { Iframe } from "../state/app/useIframeLoading";

const StyledButtonGroup = styled.div`
display:flex;
column-gap: 5px;

button {
    display:block;
    flex: 1;

    &.active {
        color: rgb(254, 195, 7);
    }
}
`

const StyledBootstrapSelect = styled.select.attrs({
    className: 'form-select form-select-sm'
})`
border: 1px solid ${({ theme }) => theme.secondaryColor};
`

const FloatingStatsNav = styled.div<{ width: number | string, open?: boolean }>`
display: ${p => p.open ? 'flex' : 'none'};
flex-direction: column;
row-gap: 5px;
position: fixed;
top: 136px;
right: 5px;

button {
    width: ${p => p.width}px;
}
`



type StatsButtonProps = {
    route: string;
    label: string;
}
function StatsButton({ route, label }: StatsButtonProps) {

    const app = useAppState()
    const StatsWidget = useStatsWidget()

    function onClick() {
        app.ui('leftTray').set(<Button onClick={() => window.history.back()} style={{ margin: 0 }}>Back</Button>);
        StatsWidget.setPage(route)
    }

    // if the current page is the same as the route, make the button active
    if (StatsWidget.page === route) {
        return <Button className="active" onClick={onClick} variant='inverted'>{label}</Button>
    }

    // otherwise, make it inactive
    return <Button onClick={onClick} variant='inverted'>{label}</Button>

}

export function TeamStatsPage() {

    const app = useAppState()
    const statsRef = useRef<HTMLButtonElement>(null)
    const StatsWidget = useStatsWidget()

    useEffect(() => {

        return () => {
            app.ui('leftTray').clear()
        }

    }, [])

    useEffect(() => {

        // don't process empty seasons
        if (StatsWidget.seasons.length == 0) return;

        const firstSeason = StatsWidget.seasons[0]
        StatsWidget.setLocation(firstSeason.id, `teams/${firstSeason.team.id}/standings`)

    }, [StatsWidget.seasons.length])

    return (<>
        <ActionBar isMobile={app.layout.showMobile}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5, alignItems: 'stretch' }}>
                <div>
                    <FormWrapper>
                        <StyledBootstrapSelect onChange={(e) => StatsWidget.setSeason(e.target.value)}>
                            {StatsWidget.seasons.map((season, index) => <option key={index} value={season.id}>{season.title}</option>)}
                        </StyledBootstrapSelect>
                    </FormWrapper>
                </div>
                <StyledButtonGroup>
                    <StatsButton route='scores' label="Scores" />
                    <StatsButton route='schedule' label="Schedule" />
                    <StatsButton route={`teams/${StatsWidget.team?.id}/standings`} label="Standings" />
                    {app.layout.showMobile && <>
                        <FloatingStatsNav width={statsRef.current?.clientWidth || 0} open={StatsWidget.isStatsMenuOpen}>
                            <StatsButton route='players' label="Players" />
                            <StatsButton route='goalies' label="Goalies" />
                        </FloatingStatsNav>

                        <Button innerRef={statsRef} onClick={StatsWidget.toggleStatsMenu} variant='inverted' gs-style={StatsWidget.isStatsMenuOpen ? "outline" : "solid"}>Stats</Button>
                    </>}
                    {app.layout.showDesktop && <>
                        <StatsButton route='players' label="Players" />
                        <StatsButton route='goalies' label="Goalies" />
                    </>}
                </StyledButtonGroup>
            </div>
        </ActionBar>

        <Iframe id="stats-widget" isloading={false} src={StatsWidget.src} />
    </>)
}