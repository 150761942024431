import IframeWithOtp from "../components/IframeWithOtp";
import { useAppState } from "../state/app/useAppState";
import { Iframe, useIframeLoadingState } from "../state/app/useIframeLoading";

export function LegacyNatificationsPage()  {
  
  const app = useAppState()

  return <>
    <IframeWithOtp id="user-notifications" src={`${app.config.apps.leagueDashboard}/account/email-notification-settings`} />
  </>;
}
