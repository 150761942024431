import { Button, Logo } from "@gamesheet/ui";
import { PropsWithChildren, useEffect, useState } from "react";
import styled from 'styled-components';
import { auth } from '../libs/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAppState } from "../state/app/useAppState";
import bg2 from '../assets/images/bg2.jpg'
import { useUserState } from "../state/user/useUserState";

const StyledLoginLayout = styled.div`

> div {
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-image: url(${bg2});
    
    
    opacity: 1;
    transition: opacity 1s ease-in-out;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-style: italic;
    font-variant: small-caps;
    color: black;
    gap: 15px;

    input {
        padding: 8px;
        display: block;
        font-size: 16px;
        margin-bottom: 16px;
    }

    > div, form {
        display: flex;
        gap: 15px;
        flex-direction: column;
        align-items: stretch;

        > div {
            display: block;
            button {
                width: 100%;
            }
        }
    }
}
`

const Error = styled.div`
  display: block;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #8c3641;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative;
  font-size: .875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-family: Rubik,Arial,sans-serif;
  font-weight: 400;
`;

// add a firebase login form to the login screen

export function LoginLayout(props:PropsWithChildren<{}>){

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const user = useUserState()
    
    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
          await user.SignIn(email, password);
          setError(''); // Clear any previous error if login is successful
        } catch (err: any) {
          setError(err); // Set the error state with the error message
        }
      };

    useEffect(() => {
      setError("");
    }, [email, password]);

    return (
        <StyledLoginLayout>
            <div>
                <Logo logo='primary' color='white' contrast="darkbg" size='xxl'/>
                <form onSubmit={login}>
                    <div>
                        <input type={'email'} placeholder={'Email Address'} onChange={(e)=>setEmail(e.target.value)} value={email} />
                    </div>
                    <div>
                        <input type={'password'} placeholder={'Password'} onChange={(e)=>setPassword(e.target.value)} value={password} />
                    </div>
                    <div>
                        <Button type="submit" gs-style="solid" variant="inverted" style={{display:'block'}}>Login</Button>
                    </div>
                    
                </form>
                {error && <Error color={`${error ? "danger" : ""}`}>{error}</Error>}

            </div>

        </StyledLoginLayout>
    );
}