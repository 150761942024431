// PlayerForm.tsx
import { FormWrapper, Button, Title, ButtonGroup } from "@gamesheet/ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "@gamesheet/ui/build/index.css";
import { createYearRange, getDaysInMonth, months } from "../libs/constants";
import { getData } from "country-list";
import { RegionDropdown } from "react-country-region-selector";
import { PlayerFormData } from "../state/data/usePlayerData";

type PlayerFormProps = {
  formData: PlayerFormData;
  setFormData: React.Dispatch<React.SetStateAction<PlayerFormData>>;
};

const PlayerForm = ({ formData, setFormData }:PlayerFormProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleRegionChange(value: string, event: React.ChangeEvent<Element>): void {
    setFormData({ ...formData, province: value });
  }

  const daysForBirth = useMemo(() => {
    return formData.birthMonth ? getDaysInMonth(formData.birthMonth) : [];
  }, [formData.birthMonth]);

  const countryList = useMemo(getData, []);

  //TODO list: Convert birthdate to correct format for db
  // Stylign for birthday section
  // Add validation for required fields
  // Jersey number comparison across team
  // Status value is a string here but it should be a boolean in the DB

  return (
    <FormWrapper>
      <fieldset>
        <div className="form-floating mb-3">
          <input type="text" name="firstName" id="firstName" className="form-control" placeholder="First Name" onChange={handleChange} value={formData.firstName} />
          <label htmlFor="firstName" className="floating">
            First Name
          </label>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="lastName" id="lastName" className="form-control" placeholder="Last Name" onChange={handleChange} value={formData.lastName} />
          <label htmlFor="lastName" className="floating">
            Last Name
          </label>
        </div>

        <Title bartype="divider" text="Roster Info" size="h2"/>

        {/* TODO: We need a way to compare jersey numbers across other players on the team and prevent them from being the same.  */}
        <div className="form-floating mb-3">
          <input type="number" name="jersey" id="jersey" className="form-control" placeholder="0" onChange={handleChange} value={formData.jersey} />
          <label htmlFor="jersey" className="floating">
            Jersey
          </label>
        </div>

        <div className="form-floating mb-3">
          {/* /Users/drewdavidson/gamesheet/the-version-3/src/components/TeamPlayerForm/TeamPlayerForm.js
    On the the dash board these values are booleans -  but in typescript you cant assign a boolean to an option's value. I don't want to send bad data to the db. Maybe we do a radio box here? */}
          <select name="status" id="status" className="form-select" onChange={handleChange} value={formData.status}>
            <option value={"regular"}>Regular</option>
            <option value={"affiliated"}>Affiliated</option>
          </select>
          <label htmlFor="status" className="floating">
            Status
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="position" id="position" className="form-select" onChange={handleChange} value={formData.position}>
            <option value="">None</option>
            <option value="right_wing">Right Wing</option>
            <option value="left_wing">Left Wing</option>
            <option value="centre">Centre</option>
            <option value="forward">Forward</option>
            <option value="defence">Defence</option>
            <option value="goalie">Goalie</option>
            <option value="transition">Transition</option>
          </select>

          <label htmlFor="position" className="floating">
            Position
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="duty" id="duty" className="form-select" onChange={handleChange} value={formData.duty}>
            <option value="">No designation</option>
            <option value="captain">Captain</option>
            <option value="alternate_captain">Alternate Captain</option>
          </select>
          <label htmlFor="duty" className="floating">
            Designation
          </label>
        </div>

        <Title bartype="divider" text="Player Info" size="h2"/>
    
        <div className="row no-gutters">
          <div className="col">
            <div className="form-floating mb-3">
              <select name="birthMonth" id="birthMonth" className="form-select" onChange={handleChange} value={formData.birthMonth}>
                <option disabled value="">
                  Month
                </option>
                {months.map((month: string, index: number) => {
                  return (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="birthMonth" className="floating">
                Month
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <select name="birthDay" id="birthDay" className="form-select" onChange={handleChange} value={formData.birthDay}>
                <option disabled value="">
                  Day
                </option>
                {daysForBirth.map((day: string, index: number) => {
                  return (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="birthDay" className="floating">
                Day
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <select name="birthYear" id="birthYear" className="form-select" onChange={handleChange} value={formData.birthYear}>
                <option disabled value="">
                  Year
                </option>
                {daysForBirth.length > 0
                  ? createYearRange().map((year: string, index: number) => {
                    return (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    );
                  })
                  : null}
              </select>
              <label htmlFor="birthYear" className="floating">
                Year
              </label>
            </div>
          </div>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="weight" id="weight" className="form-control" placeholder="Weight" onChange={handleChange} value={formData.weight} />
          <label htmlFor="weight" className="floating">
            Weight
          </label>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="height" id="height" className="form-control" placeholder="Height" onChange={handleChange} value={formData.height} />
          <label htmlFor="externalId" className="floating">
            Height
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="shotHand" id="shotHand" className="form-select" onChange={handleChange} value={formData.shotHand}>
            <option value="" />
            <option value="right">Right</option>
            <option value="left">Left</option>
          </select>

          <label htmlFor="shotHand" className="floating">
            Shot-hand / Catch Hand
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="country" id="country" className="form-select" onChange={handleChange} value={formData.country}>
            <option disabled value="">
              Country
            </option>
            <option value={"CA"}>Canada</option>
            <option value={"US"}>United States of America</option>
            {countryList.map((country, index) => {
              return (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              );
            })}
          </select>
          <label htmlFor="country" className="floating">
            Country
          </label>
        </div>

        {formData.country === "US" || formData.country === "CA" ? (
          <div className="form-floating mb-3">
            <RegionDropdown
              classes="form-control mb-3"
              countryValueType="short"
              id="playerProvince"
              name="province"
              value={formData?.province || ""}
              country={formData.country}
              defaultOptionLabel=""
              onChange={handleRegionChange}
            />
            <label htmlFor="externalId" className="floating">
              Province
            </label>
          </div>
        ) : (
          <div className="form-floating mb-3">
            <input type="text" name="province" id="province" className="form-control" placeholder="province" onChange={handleChange} value={formData.province} />
            <label htmlFor="externalId" className="floating">
              Province
            </label>
          </div>
        )}

        <div className="form-floating mb-3">
          <input name="hometown" id="hometown" className="form-control" placeholder="City / Town" onChange={handleChange} value={formData.hometown} />
          <label htmlFor="hometown" className="floating">
            City / Town
          </label>
        </div>
        
        <Title bartype="divider" text="Player Bio" size="h2"/>

        <div className="form-floating mb-3">
          <input type="text" name="draftedBy" id="draftedBy" className="form-control" placeholder="Drafted By" onChange={handleChange} value={formData.draftedBy} />
          <label htmlFor="draftedBy" className="floating">
            Drafted By
          </label>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="committedTo" id="committedTo" className="form-control" placeholder="Committed to" onChange={handleChange} value={formData.committedTo} />
          <label htmlFor="committedTo" className="floating">
            Commited to
          </label>
        </div>

        <div className="form-floating mb-3">
          <textarea name="biography" id="biography" className="form-control" placeholder="Bio" onChange={handleChange} value={formData.biography} />
          <label htmlFor="biography" className="floating">
            Bio
          </label>
        </div>
      </fieldset>
    </FormWrapper>
  );
};

export default PlayerForm;
