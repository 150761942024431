import { useCallback, useEffect, useMemo, useRef } from "react";
import { useUserState } from "../user/useUserState";
import { useMessageListener } from "./useWindowState";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

type NestedAppConfig = {
    name: string
    url: string
}

type NestedApp = ReturnType<typeof useCreateNestedApp>
const NestedApplicationsState = atom<{ [key:string]: NestedApp }>({
    key: 'nestedApplicationState',
    default: {}
})

export function useNestedApp(name:string){
    const nestedApps = useRecoilValue(NestedApplicationsState)
    return nestedApps[ name ]
}

export function useCreateNestedApp( config:NestedAppConfig ) {

    const user = useUserState();
    const otp = useMemo(async () => user.GetOtp(), [ user.isLoggedIn, user.email ])
    const messages = useMessageListener()
    // const setNestedApps = useSetRecoilState(NestedApplicationsState)
    const frame = useMemo<HTMLIFrameElement | null>(() => document.getElementById(config.name) as HTMLIFrameElement | null, [config.name])

    // navigate the frame to a new location by sending a navigation message
    const navigate = useCallback((location: string) => {

        show()
        frame?.contentWindow?.postMessage({
            type: 'location',
            source: 'team-dashboard',
            location
        }, config.url)

    }, [ frame ])

    // activate the frame
    const show = useCallback(() => {
        frame?.classList.add('active')
    }, [ frame ])

    // deactivate the frame
    const hide = useCallback(() => {
        frame?.classList.remove('active')
    }, [ frame ])

    // add a listener to the iframe
    const onMessage = (listener:(event:MessageEvent) => void) => {
        
        const scopedListener = (event:MessageEvent) => {
            if (event.origin == config.url && event.data.source == config.name){
                listener(event)
            }
        }

        messages.add(scopedListener)
        return () => messages.remove(scopedListener)
    }

    // if we have both a frame and an otp, set the frame src to the root url
    useEffect(() => {
        
        if (!otp || !frame) return
        const url = `${config.url}/?otp=${otp}`
        frame.getAttribute('src') == url || frame.setAttribute('src', url)

    }, [ otp, frame ])

    const nestedApp = {
        ...config,
        navigate,
        show,
        hide,
        onMessage
    }

    

    return nestedApp;

}