import { Button, FormWrapper } from "@gamesheet/ui";
import styled from "styled-components";
import { useInvitationCode } from "../state/data/useInvitationCode";
import { PageLoading } from "../components/PageLoading";

const Error = styled.span`
  color: red;
`;

export function InvitationPage() {

  //TODO: We need error handling from accepting an invitation. Currently it will send a 200 response even if the invitation code is invalid.
  const invitationCode = useInvitationCode();

  if(invitationCode.isSubmitting){
    return <PageLoading />
  }

  return (
    <FormWrapper>
      
      <h2>Add New Team</h2>
      <form onSubmit={invitationCode.handleSubmit}>
        <div className="form-floating mb-3">
          <input required type="text" name="invitationCode" id="invitationCode" className="form-control" placeholder="Invitation Code" onChange={invitationCode.handleChange} value={invitationCode.value} />
          <label htmlFor="invitationCode" className="floating">
            {invitationCode.error ? <Error>{invitationCode.error}</Error> : `Invitation Code`}
          </label>
        </div>
      </form>

      <Button style={{ display:'block',width:'100%' }} onClick={invitationCode.handleSubmit}>Accept Invitation</Button>

    </FormWrapper>
  );
}
