import "@gamesheet/ui/build/index.css"
import TagManager from 'react-gtm-module';
import Smartlook from "smartlook-client"
import styled from 'styled-components';

import { useAppState } from './state/app/useAppState';
import { LoginLayout } from "./layouts/LoginLayout";
import { LoadingLayout } from "./layouts/LoadingLayout";
import { Outlet } from 'react-router-dom';
import { useWindowState } from './state/app/useWindowState';
import { useSetUserState } from './state/user/useUserState';
import { Suspense, useEffect } from 'react';

function App() {

  useSetUserState()
  useWindowState()

  const app = useAppState()

  useEffect(() => {
    if (app.isLoggedin && app.activeUser?.uid && app.activeUser?.email) {
      const tagManagerArgs = { userId: app.activeUser?.uid, userRole: "manager" }
      TagManager.dataLayer({ dataLayer: tagManagerArgs })
      Smartlook.identify(app.activeUser?.uid, { email: app.activeUser?.email, userRole: "manager" })
    }
  }, [app.activeUser?.uid, app.activeUser?.email, app.isLoggedin])

  if (!app.loading.isComplete('firebaseAuth')) {
    return <LoadingLayout />
  }

  if (!app.isLoggedin) {
    return <LoginLayout />
  }

  return <>
    <Suspense fallback={<LoadingLayout />}> 
      <Outlet />
    </Suspense>
  </>;

}

export default App;

// 