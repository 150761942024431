import config from "../../config";

export const getOtp = async (accessToken: string) => {
  
  if (!accessToken) {
    return "";
  }


  try {
    const endpoint = `${config.gateways.auth}/auth/v4/otp`;
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      return "";
    }

    const data = await response.json();
    return data.otp;

  } catch (error) {
    return "";
  }
}
