import { router as teamRouter } from './apps/teams/router';
import { router as leagueRouter } from './apps/leagues/router';
import config from "./config";
import { RouterProvider } from 'react-router-dom';
import { useDeviceState, useSetDeviceState } from './state/app/useDeviceState';

let router:typeof teamRouter;
switch(config.app){
  case 'team': router = teamRouter; break;
  case 'league': router = leagueRouter; break;
  default: throw new Error(`Invalid app type: ${config.app}`);
}

export function GameSheetRouter(){

    useSetDeviceState()
    const device = useDeviceState()

    return <>
      <RouterProvider router={router(device)} />
    </>

}