import { useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { firestore } from "../../libs/firebase";
import { collection, query, onSnapshot, where, getCountFromServer } from "firebase/firestore";
import { useAppState } from "../app/useAppState";
import { RosterCoach, RosterPlayer } from "./useTeamRoster";
import { asyncFilter } from "../../libs/asyncFilter";

export type TeamData = {
    id: string;
    title: string;
    logo: string;
    club: string;
}

export type TeamsData = {
    data: TeamData[],
    lastUpdated: number,
}

// imported into useSeasonTeams!
export const teamsDataState = atom<TeamsData>({
    key: 'teamsDataState',
    default: {
        data: [],
        lastUpdated: 0,
    },
});

export function useReadTeamsData() {

    const app = useAppState()
    const setTeamsData = useSetRecoilState(teamsDataState)

    useEffect(() => {

        // only logged in user will be able to read teams data
        if(!app.activeUser) return;
        
        const q = query(collection(firestore, "teams"), where("users","array-contains", app.activeUser.email))
        return onSnapshot(q, async (querySnapshot) => {
            
            let data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as TeamData)
            data = await asyncFilter<TeamData>(data, async (team) => {

                const q2 = query(collection(firestore, `teams/${team.id}/seasonteams`), where("deletedAt.Valid","==",false))
                const snapshot = await getCountFromServer(q2)
                return snapshot.data().count > 0

            })
            
            setTeamsData({
                data,
                lastUpdated: Date.now(),
            })
            
            let selectedTeam:string = app.selectedTeam

            // if a new prototeam is added, select it
            querySnapshot.docChanges().forEach((change) => {
                
                if (change.type === "added") {
                    selectedTeam = change.doc.id
                }

                if(change.type === "removed" && app.selectedTeam === change.doc.id){
                    selectedTeam = ""
                }

            });
            
            // if no team is selected, select the first one
            if(!selectedTeam && data[0]){
                selectedTeam = data[0].id
            }

            app.setSelectedTeam(selectedTeam)
            app.loading.complete('teams')

        })

    }, [ app.activeUser ])

}

export function useTeamsData() {
    const data = useRecoilValue(teamsDataState)

    return {
        onChange: data.lastUpdated,
        all: () => data.data,
        teams: data.data
    }
}

type OriginTeamData = {
    id: string;
    title: string;
    logo: string;

    division: {
        id: string;
        title: string;
    }

    roster: {
        players: number;
        coaches: number;
    }
}

type OriginTeamsData = OriginTeamData[];

const originTeamsDataState = atom<OriginTeamsData>({
    key: 'originTeamsDataState',
    default: [],
});

export function useOriginTeamsData(seasonId?: string) {

    // guard empty seasonId
    if(!seasonId) throw new Error('Season ID is required to fetch teams data.')
    
    const app = useAppState()
    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useRecoilState(originTeamsDataState)

    useEffect(() => {

        const url = `${app.config.gateways.data}/teams/v4?filter[seasons]=${seasonId}&filter[limit]=25`;
        setLoading(true);

        (async () => {

            const response = await fetch(url)
            const data = await response.json()
            setTeams(data.data)
            setLoading(false)

        })()

    }, [seasonId])

    return {
        loading,
        onChange: teams.length,
        all: () => teams,
        teams
    }

}

export function useOriginTeamData(seasonId?: string, teamId?: string) {

    if (!teamId) throw new Error('Team ID is required to fetch team data.')

    const { loading, teams } = useOriginTeamsData(seasonId)
    const team = teams.find(team => team.id == teamId)

    return {
        loading,
        data: team
    }

}

export function useOriginSeasonTeamRosterData(teamId?: string) {

    if (!teamId) throw new Error('Team ID is required to fetch team roster data.')

    const app = useAppState()
    const [loading, setLoading] = useState(false)
    const [players, setPlayers] = useState<RosterPlayer[]>([])
    const [goalies, setGoalies] = useState<RosterPlayer[]>([])
    const [coaches, setCoaches] = useState<RosterCoach[]>([])

    useEffect(() => {

        const url = `${app.config.gateways.data}/teams.roster/v4?filter[teams]=${teamId}&filter[limit]=25`;
        setLoading(true);

        (async () => {

            const response = await fetch(url)
            const { data } = await response.json()
            
            const players = data.filter((player: any) => player.type === 'player' && player.position !== 'goalie') ;
            const coaches = data.filter((player: any) => player.type === 'coach');
            const goalies = data.filter((player: any) => player.position === 'goalie');

            setPlayers(players)
            setCoaches(coaches)
            setGoalies(goalies)

            setLoading(false)

        })()

    }, [ teamId ])

    return {
        loading,
        skaters: players,
        goalies,
        players,
        coaches
    }

}