import styled from "styled-components"
import { Outlet } from "react-router-dom";
import { UserBar } from "./UserBar";
import { useDeviceState } from "../state/app/useDeviceState";
import { MobileTabs } from "./MobileTabs";
import React from "react";
import { PageLoading } from "../components/PageLoading";
import { UiTrays } from "./Trays";
import { useAppState } from "../state/app/useAppState";

const StyledExternalContentLayout = styled.div<ExternalContentLayoutProps>`
position: absolute;
left: 0;
top: max(${({hideUserBar, isMobile, isActionBarOpen}) => (hideUserBar ? 0 : 60) + (isMobile ? 0 : 50) + (isActionBarOpen ? 76 : 0)}px, 0px);
transition: top 0.5s ease-in-out;
bottom: max(${({hideNavigation, isMobile}) => (hideNavigation ? 0 : 99) + (isMobile ? 0 : -93)}px, 0px);
right: 0;
background-color: #FFF;
z-index: -1;

> iframe {
    width: 100%;
    min-height: 100%;
    margin: 0;
    border: 0;

    opacity: ${(props)=>props.dim?'0.3':'1'};
    transition: opacity 1s ease 0s;
}

`

type ExternalContentLayoutProps = {
    hideUserBar?: boolean;
    hideNavigation?: boolean;
    isActionBarOpen?: boolean;
    isMobile?: boolean;
    dim?: boolean;
}

export function ExternalContentLayout(props: ExternalContentLayoutProps){
    
    const app = useAppState()
    
    return <>
        { props.hideUserBar || <UserBar /> }
        { props.hideNavigation || <MobileTabs /> }
        <UiTrays />
        <StyledExternalContentLayout isActionBarOpen={props.isActionBarOpen} hideNavigation={props.hideNavigation} hideUserBar={props.hideUserBar} isMobile={app.device.isMobile} dim={app.menu.isOpen}>
            <React.Suspense fallback={<PageLoading />}>
                <Outlet />
            </React.Suspense>
        </StyledExternalContentLayout>
    </>
}