import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Button = styled.a`
text-align: center;
font-size: 0.8rem;

&#btn-profile {
     min-width: 60px;
     max-width: 60px;
}
`

export type NavigationItem = {
    to?: string; 
    action?: React.MouseEventHandler<HTMLAnchorElement>;
    label?: string;
    icon?: JSX.Element;
    desktop?: boolean;
    mobile?: 'left' | 'right' | 'icon';
}

export function NavButton({ button }:{button:NavigationItem}){
    
    return <>
        { button.to && <>
            <NavLink to={button.to}>
                {button.icon}<br />
                {button.label}
            </NavLink>
        </>}
        { button.action && <>
            <Button onClick={button.action}>
                {button.icon}<br />
                {button.label}
            </Button>
        </> }
    </>

}