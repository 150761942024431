import { useState, useEffect, useReducer, Reducer } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useAppState } from "../app/useAppState";
import { useUserState } from "../user/useUserState";
import { useSeasonsData } from "./useSeasonTeams";

export const HCR_ORAGNIZATIONS = [
    {
      "id": "1eb5d98e-ac53-658c-b920-06ba8f7ba4e0",
      "title": "BC HOCKEY",
    },
    {
      "id": "1eb5d995-f3f1-6716-9826-06ba8f7ba4e0",
      "title": "HOCKEY ALBERTA",
    },
    {
      "id": "1eb5d9a7-b30a-615a-aa54-06ba8f7ba4e0",
      "title": "HOCKEY NEW BRUNSWICK",
    },
    {
      "id": "1eb5d9a9-df1c-6656-b377-06ba8f7ba4e0",
      "title": "HOCKEY NOVA SCOTIA",
    },
    {
      "id": "1eb5d9ad-2c91-658c-8d16-06ba8f7ba4e0",
      "title": "HOCKEY PEI",
    },
    {
      "id": "1eb5da8d-5868-69c2-8330-06ba8f7ba4e0",
      "title": "NORTHERN ONTARIO HOCKEY ASSOCIATION",
    },
    {
      "id": "1eb5da90-685c-62fe-875d-06ba8f7ba4e0",
      "title": "ALLIANCE HOCKEY",
    },
    {
      "id": "1eb5da90-bb47-6eb4-aca3-06ba8f7ba4e0",
      "title": "ONTARIO MINOR HOCKEY ASSOCIATION",
    }
  ];

export const initialState = {
    importId: "",
    registryId: "",
    isImporting: false,
    isComplete: false,
    isFailed: false,
    failureMessage: "",
    inputIsInvalid: false,
    progress: {
        total: 0,
        requested: 0,
        failed: 0,
        pending: 0,
        success: 0,
    },
};

type ImportGroup = {
    coaches: (number | string)[],
    players: (number | string)[],
    divisions: (number | string)[],
    teams: (number | string)[],
}

export type ImportProcedure = {
    completed: ImportGroup,
    failed: ImportGroup,
    started: ImportGroup,
    import: ImportGroup,
    options: {
        [key: string]: any
    },
    errorLog: Array<{
        error: {
            message: string,
            code: number,
            name: string,
            stack: string[],
        },
        id: string;
        type: string;
    }>
}

export type RegistrySyncState = {
    importId: string;
    registryId: string;
    isImporting: boolean;
    isComplete: boolean;
    isFailed: boolean;
    failureMessage: string;
    inputIsInvalid: boolean;
    progress: {
        total: number;
        requested: number;
        failed: number;
        pending: number;
        success: number;
    };
};

export type RegistrySyncAction =
    | { type: "RESET" }
    | { type: "SET_IMPORT_ID"; payload: { importId: string } }
    | {
        type: "SET_IMPORT_STATUS";
        payload: {
            isImporting?: boolean;
            isComplete?: boolean;
            isFailed?: boolean;
            failureMessage?: string;
            inputIsInvalid?: boolean;
            registryId?: string;
        };
    }
    | { type: "SET_PROGRESS"; payload: RegistrySyncState["progress"] };

export const reducer = (state: RegistrySyncState, action: RegistrySyncAction) => {
    switch (action.type) {
        case 'RESET':
            return initialState;

        case 'SET_IMPORT_ID':
            return { ...state, importId: action.payload.importId };

        case 'SET_IMPORT_STATUS':
            return { ...state, ...action.payload };

        case 'SET_PROGRESS':
            return { ...state, progress: action.payload };

        default:
            return state;
    }
};

function count(i: ImportGroup) {
    return Object.values(i).reduce((a, b) => a + b.length, 0);
}

export function useRegistryImport(registry: 'usah' | 'hcr') {

    const app = useAppState()
    const user = useUserState();
    const [state, dispatch] = useReducer(reducer, initialState);
    const seasonsData = useSeasonsData();

    useEffect(() => {

        // guard from empty import
        if (!state.importId) return;

        const importProcedureRef = doc(app.connections.firestore, `services/registrySync/importProcedures/${state.importId}`);
        const unsubscribe = onSnapshot(importProcedureRef, (doc) => {
            const importProcedure: ImportProcedure = doc.data() as ImportProcedure;

            const total = count(importProcedure?.import);
            const started = count(importProcedure?.started);
            const completed = count(importProcedure?.completed);
            const failed = count(importProcedure?.failed);

            dispatch({
                type: 'SET_PROGRESS',
                payload: {
                    total,
                    requested: started - completed - failed,
                    failed,
                    pending: total - started,
                    success: completed,
                }
            });

            if (completed + failed != total) return;

            switch (true) {

                // we couldn't find the team
                case total === failed && total === 1:
                    dispatch({
                        type: 'SET_IMPORT_STATUS',
                        payload: { isImporting: false, isFailed: true, inputIsInvalid: true, failureMessage: `We were unable to locate an approved roster matching Team ID '${state.registryId}'. Double check that your Team ID is correct and your roster is approved before trying again.` }
                    });
                    break;

                // if any failed, set failed
                case !!failed:
                    dispatch({
                        type: 'SET_IMPORT_STATUS',
                        payload: { isImporting: false, isFailed: true, inputIsInvalid: true, failureMessage: "Import Failed" }
                    });
                    break;

                // if all succeeded, set complete
                default:
                    dispatch({ type: 'SET_IMPORT_STATUS', payload: { isImporting: false, isComplete: true } });
                    break;
            }

        });

        return () => unsubscribe();

    }, [state.importId]);

    async function start(registryId: string, hcrOrganizationId: string) {

        dispatch({ type: 'RESET' });

        dispatch({ type: 'SET_IMPORT_STATUS', payload: {
            isImporting: true,
            registryId: registryId,
        }});

        fetch(
            `${app.config.services.registryApi}/v4/rpc/teamImportProcedure`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${user.tokens.access}`,
                },
                body: JSON.stringify({
                    registryId,
                    code: registry,
                    scope: {
                        teamId: seasonsData.teams[0]?.id?.toString(),
                        seasonId: seasonsData.teams[0].season.id?.toString(),
                    },
                    ...(registry === 'hcr' && {registryScope: {
                        organisationId: hcrOrganizationId
                    }} || {}),
                }),
            }
        )
        
        // get import id
        .then((response) => {
            if (response.status !== 201) {
                if (response.status === 409) {
                    throw new Error("A similar team already exists in this season");
                }

                throw new Error(`Code ${response.status}`);
            }

            return response.json();
        })

        // set import id
        .then((data) => {
            dispatch({
                type: "SET_IMPORT_ID",
                payload: { importId: data.id },
            });
        })

        // handle errors
        .catch(err => {
            console.error(err);
            dispatch({
                type: "SET_IMPORT_STATUS",
                payload: {
                    isFailed: true,
                    inputIsInvalid: true,
                    isImporting: false,
                    failureMessage: err.response?.data?.message || err.message,
                },
            });
        });

    }

    function reset() {
        dispatch({ type: 'RESET' });
    }

    return {
        ...state,
        HCR_ORAGNIZATIONS,
        reset,
        start,
    }

}