import { FormWrapper, Title, Button } from "@gamesheet/ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ErrorCard, SuccessCard } from "../components/EntityCard";
import { ProgressBar } from "../components/ProgressBar";
import { useAppState } from "../state/app/useAppState";
import { useRegistryImport } from "../state/data/useRegistryImport";

const registries = [{
    id: 'usah',
    name: 'USA Hockey',
},{
    id: 'hcr',
    name: 'Hockey Canada',
}]


export function ImportFromRegistry(){

    const app = useAppState()
    const registryId = useParams().registry as 'usah' | 'hcr';
    const registry = registries.find(r => r.id === registryId);
    const [ registryTeamId, setRegistryTeamId ] = useState('');
    const [ hcrOrganizationId, setHcrOrganizationId ] = useState('');
    const process = useRegistryImport(registryId);

    useEffect(() => {
        if (registryId === 'hcr') {
            setHcrOrganizationId(process.HCR_ORAGNIZATIONS[0].id)
        }
    }, []);

    useEffect(() => {
        app.ui('leftTray').set(<Button onClick={app.navigateOnClick('/roster/team')}>Back</Button>)
        return () => app.ui('leftTray').clear()
    }, [registryId])

    if(!registry) {
        return <ErrorCard title="Registry Not Found" message="The registry you are trying to import from was not found." />
    }

    return <>
        {/* <Title bartype="divider" size="h3" text="Registry Import" /> */}
        <Title bartype="bar" size="h6" text={`Import from ${registry.name}`} variant="inverted" />
        <FormWrapper>
            { registryId === 'hcr' && <>
                <div className="form-floating mb-1">
                    <select className="form-select" onChange={(e) => setHcrOrganizationId(e.target.value)}>
                        { process.HCR_ORAGNIZATIONS.map(org => <option value={org.id}>{org.title}</option>) }
                    </select>
                    <label htmlFor="floatingSelect">Hockey Canada Organization</label>
                </div>
            </>}
            <div className="form-floating mb-1">
                <input type="email" className="form-control" placeholder="COH1071-989" onChange={(e) => setRegistryTeamId(e.target.value)} value={registryTeamId} />
                <label htmlFor="floatingInput">{registry.name} Team ID</label>
            </div>
            <Button type="submit" width="block" onClick={() => process.start(registryTeamId, hcrOrganizationId)} disabled={!registryTeamId}>Start Import Process</Button>
            <div className="form-text">
                Enter your team's {registry.name} ID to begin the import process.
            </div>
        </FormWrapper>

        { process.isFailed && <>
            <ErrorCard title="" message={process.failureMessage} />
        </>}

        { process.isComplete && <>
            <SuccessCard title="Done" />
        </>}

        { process.isImporting && <>
            <Title bartype="bar" size="h6" text="Importing..." variant="inverted" />
            <ProgressBar title={registryId} {...process.progress} />
        </>}
    </>

}