import { collection, getDocs, onSnapshot, query } from "@firebase/firestore";
import { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { useAppState } from "../app/useAppState"

export type RosterPerson = {
    id: string;
    firstName: string;
    lastName: string;
    rosterLocked: boolean;
    teamId: string;
}

export type RosterCoach = RosterPerson & {
    position: string;
};

export type RosterPlayer = RosterPerson & {
    position: string;
    jersey: string;
    photo: string;
    status: string;
    duty: string;
    birthDate: {
        Time: string;
        Valid: boolean;
    },
    birthDay: string;
    birthMonth: string;
    birthYear: string;
    weight: string;
    height: string;
    shotHand: "" | "right" | "left";
    country: string;
    province: string;
    homeTown: string;
    draftedBy: string;
    committedTo: string;
    bio: string;
};

export type Roster = {
    coaches: RosterCoach[];
    skaters: RosterPlayer[];
    goalies: RosterPlayer[];
};

const rosterState = atom<Roster>({
    key: 'rosterState',
    default: {
        coaches: [],
        skaters: [],
        goalies: []
    }
})

export type ReadableTeamRoster = Roster & {
    players: RosterPlayer[];
} 

export function useReadTeamRosterData(){

    const app = useAppState()
    const [ roster, setRoster ] = useRecoilState(rosterState)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    useEffect(() => {
        
        if(!app.selectedTeam) return;
        
        const playersQuery = query(collection(app.connections.firestore, `teams/${app.selectedTeam}/players`))
        const unsubPlayers = onSnapshot(playersQuery, (querySnapshot) => {
            const players = querySnapshot.docs.map((doc) => ({ 
                id: doc.id,
                ...doc.data(), 
                jersey: doc.data().number,
                birthYear: doc.data().birthDate.Valid ? doc.data().birthDate.Time.substring(0, 4) : "",
                birthMonth: doc.data().birthDate.Valid ? months[parseInt(doc.data().birthDate.Time.substring(5, 7), 10) - 1]: "",
                birthDay: doc.data().birthDate.Valid ? doc.data().birthDate.Time.substring(8, 10): "",
            }) as RosterPlayer)
            const skaters = players.filter((player) => player.position !== 'goalie')
            const goalies = players.filter((player) => player.position === 'goalie')
            
            setRoster((roster) => ({
                ...roster,
                skaters,
                goalies
            }))

        });

        const coachesQuery = query(collection(app.connections.firestore, `teams/${app.selectedTeam}/coaches`))
        const unsubCoaches = onSnapshot(coachesQuery, (querySnapshot) => {
            
            const coaches = querySnapshot.docs.map((doc) => ({  
                id: doc.id, 
                ...doc.data(),
            }) as RosterCoach)
            setRoster((roster) => ({
                ...roster,
                coaches
            }))
        
        })
        
        return () => {
            unsubPlayers()
            unsubCoaches()
        }


    }, [app.selectedTeam])

    return { 
        roster
    }

}

export function useTeamRoster() {
    
    const roster = useRecoilValue(rosterState)
    
    return {
        ...roster,
        players: [...roster.skaters, ...roster.goalies],
        findPlayer(id: string){
            return [...roster.skaters, ...roster.goalies].find((player) => player.id === id)
        },
        findCoach(id: string){
            return roster.coaches.find((coach) => coach.id === id)
        }
    }
}