import { config as devConfig } from "./config.dev"
import { config as qaConfig } from "./config.qa"
import { config as betaConfig } from "./config.beta"
import { config as prodConfig } from "./config.prod"
import { Config } from "./type"

const branch = process.env.REACT_APP_BRANCH || "main"

let config:Config = devConfig;

switch (true) {
    case /main|(fix\/.*)|(qa.*)/i.test(branch): config = qaConfig; break;
    case /beta/i.test(branch): config = betaConfig; break;
    case /production|demo|stable|staging|beta/i.test(branch): config = prodConfig; break;
    default: config = devConfig; break;
}

export default {
    ...config,
    app: process.env.REACT_APP_NAME || "app",
};