import { useCallback, useEffect, useState } from "react"
import { useAppState } from "../../state/app/useAppState"
import { Menu } from "@gamesheet/ui"

import styled from "styled-components";
import { useUserState } from "../../state/user/useUserState";
import { useEventsState } from "../../state/app/useEventsStats";

type MenuContainerProps = { 
    open?: boolean; 
}

const MobileMenuContainer = styled.div<MenuContainerProps>`
z-index: 300;

.more-menu {
    bottom: ${p => p.open ? '95px' : '-350px' };
    transition:bottom 1s ease 0s;
    padding-bottom:30px;
}
`

const DesktopMenuContainer = styled.div<MenuContainerProps>`
z-index: 300;

.more-menu {
    top: ${p => p.open ? '32px' : '-350px' };
    transition:top 1s ease 0s;
    right: calc((100vw - min(1280px, 100vw)) / 2);
}
`

const StyledMoreMenu = styled.div.attrs({
    className: "more-menu"
})`
position:fixed;
right: 0;
text-align:left;
background-color:#FFF;
z-index: 200;

box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

.menu-subtitle {
    color: #00000060;
}
`

export function MoreMenu(){

    const app = useAppState()
    const events = useEventsState()
    const user = useUserState()

    const MenuContainer = app.layout.showMobile ? MobileMenuContainer : DesktopMenuContainer
    const [ moreMenuOpen, setMoreMenuState ] = useState(false)
    
    const toggleMoreMenu = useCallback(() => {
        moreMenuOpen ? app.menu.close() : app.menu.open()
        setMoreMenuState(!moreMenuOpen)
    }, [ moreMenuOpen ])

    useEffect(() => {

        const removeToggleMoreMenuEvent = events.add('toggle-more-menu', toggleMoreMenu)
        const removeCloseMoreMenuEvent = events.add('close-more-menu', () => {
            setMoreMenuState(false)
        })

        return () => {
            removeCloseMoreMenuEvent()
            removeToggleMoreMenuEvent()
        }

    }, [ setMoreMenuState, toggleMoreMenu ])

    return <MenuContainer open={moreMenuOpen}>
        <StyledMoreMenu>
            <Menu.Container>
                <Menu.Section label="My Account">
                    <Menu.Item label="Edit Profile" onClick={app.navigateOnClick("more/myaccount/profile")} />
                    <Menu.Item label="Email Notifications" onClick={app.navigateOnClick("more/myaccount/notifications")} />
                    <Menu.Item label="Accept Invitation" onClick={app.navigateOnClick("more/myaccount/invitation")} />
                    <Menu.Item label="Sign Out" onClick={() => {
                        toggleMoreMenu()
                        user.SignOut()
                    }} />
                </Menu.Section>
                <Menu.Section label="">
                    <Menu.Item label="Help & Support" onClick={()=>{
                        toggleMoreMenu()
                        window.open("https://help.gamesheet.app/", "helpscout")
                    }}/>
                </Menu.Section>
            </Menu.Container>
        </StyledMoreMenu>
    </MenuContainer>

}