import { collection, getDocs, query } from "firebase/firestore";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { firestore } from "../../libs/firebase";
import { useAppState } from "../app/useAppState";
import { teamsDataState } from "./useTeams";
import { appState } from "../app/app.state";

export type SeasonTeamData = {
    id: string;
    title: string;
    rosterLocked: boolean;
    deletedAt: {
        Time: string;
        Valid: boolean;
    },
    division: {
        id: number;
        title: string;
    },
    season: {
        id: number;
        title: string;
    }
}

export type SeasonTeamsData = SeasonTeamData[]

const seasonTeamsDataState = selector({
    key: 'seasonTeamsDataState',
    get: async ({get}) => {
        
        const prototeams = get(teamsDataState)
        const seasonTeams = prototeams.data.map(async (team) => {
            
            const q = query(collection(firestore, `teams/${team.id}/seasonteams`))
            const querySnapshot = await getDocs(q)
            const seasonTeams = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as SeasonTeamData).filter(team => {
                return !team.deletedAt?.Valid
            })
            
            return {
                ...team,
                seasonTeams
            }
        })

        return Promise.all(seasonTeams)

    }
})

const seasonsDataState = selector({
    key: 'seasonsDataState',
    get: ({get}) => {
        
        const app = get(appState)
        const data = get(seasonTeamsDataState)
        const prototeam = data.find(team => team.id === app.selectedTeam)

        if(!prototeam) return {
            seasons: [],
            divisions: [],
            teams: []
        };

        return {
            teams: prototeam.seasonTeams,
            divisions: prototeam.seasonTeams.map((data) => {

                const { season, division, ...team } = data

                return {
                    season,
                    ...division,
                    team
                }

            }),
            // invert the team to be a property of the season
            seasons: prototeam.seasonTeams.map((data) => {

                const { season, division, ...team } = data

                return {
                    ...season,
                    division,
                    team
                }

            }),
        }
    }
})

export function useSeasonsData(){

    const prototeamData = useRecoilValue(seasonTeamsDataState)
    const data = useRecoilValue(seasonsDataState)

    return {
        teams: data.teams,
        seasons: data.seasons,
        lastUpdated: Date.now(),
        unlocked: data.teams.find(team => team.rosterLocked === false),
        prototeams: prototeamData
    }

}