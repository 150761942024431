import { Config } from "./type";
import { config as defaultConfig } from "./config.default";

const dev:Config = {
    ...defaultConfig,
    name: "Development",
    settings: {
        showDumpStateButton: true,
    },
    apps: {
        statsWidget: "https://dev.gamesheetstats.com",
        leagueDashboard: "https://dev.gamesheet.app",
    },
    firebase: {
        apiKey: "AIzaSyAev_UVG0mYihi9fGgJ0spoAaWlClgl1T8",
        authDomain: "gamesheet-v4-development-stack.firebaseapp.com",
        projectId: "gamesheet-v4-development-stack",
        storageBucket: "gamesheet-v4-development-stack.appspot.com",
        messagingSenderId: "773167879850",
        appId: "1:773167879850:web:2510ec8c21d10d75bb22d3",
        measurementId: "G-3WSP2J7YZV"
    }
}

const devLocal:Config = {
    ...dev,
    name: "Dev Local",
    gateways: {
        data: "https://gateway-data-rxj26aywsq-nn.a.run.app",
        events: "https://gateway-events-rxj26aywsq-nn.a.run.app",
        auth: "https://gateway-authserver-rxj26aywsq-nn.a.run.app",
    },
    apps: {
        ...dev.apps,
    },
}

export const config = (process.env.REACT_APP_LOCAL === "true") ? devLocal : dev;