import { Table } from "@gamesheet/ui";
import dayjs from "dayjs";
import styled from "styled-components";
import { SortOrder, TableColumn } from "./TableColumn";
import { ScheduledGameHeader } from "../../components/ScheduledGameHeader";

import { BreakPoints } from "../../libs/breakpoints";
import { TeamEvent } from "../../state/data/useTeamEvents";
import { useScheduleTableColumns } from "./useScheduleTableColumns";

const SHANE_SAID_REMOVE_NEXT = true;

export const StyledTeamSchedule = styled.div`

.title-wrapper {
    margin-bottom: 1rem;
    font-weight: 700;
}

.fixed-cols {
    flex-grow: 1;
    max-width: 100px;

    @media only screen and (max-width: ${BreakPoints.md}) {
        max-width: 60px;
    }
}

.flexible-cols {
    flex-grow: 1;
}

.column-header {
    border-bottom: 2px solid ${(props) => props.theme.secondaryColor};
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.textOnPrimaryColor};
    align-items: center;
}

.row-header {
    align-items: center;
}

.tip-container {
    .safe-position[class*="arrow-bottom-"] {
        top: 8px;
    }
}

.column {

    &.date {
        @media only screen and (max-width: ${BreakPoints.md}) {
            max-width: 60px;
        }

    }

    &.visitor {
        min-width: 175px;
        height: 100%;
        font-weight: 700;

        letter-spacing: 0 !important;



        @media only screen and (max-width: ${BreakPoints.md}) {
            min-width: 84px;
            max-width: 84px;
            white-space: normal;
        }
    }

    &.home {
        min-width: 175px;
        height: 100%;
        font-weight: 700;

        letter-spacing: 0 !important;

        @media only screen and (max-width: ${BreakPoints.md}) {
            min-width: 84px;
            max-width: 84px;
            white-space: normal;
        }
    }

    &.details {
        max-width: 100px;
    }

    &.home_logo {
        align-items: flex-start;
        flex-grow: 0;
    }

    &.visitor_logo {
        align-items: flex-end;
        flex-grow: 0;
    }

    &.location .cell {
        
    }
}

.gs-table-controls {
    display: flex;
    justify-content: flex-end;
}

.cell, .row-header {
    
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0rem;

    .data {
        
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;

        .game-link {
            
            justify-self: center;
            cursor: pointer;
            height: 100%;
            width: 100%; 

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
        }
    }
   
}

.export-button {
    max-width: 200px;
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.textOnPrimaryColor} !important;

    @media only screen and (max-width: ${BreakPoints.md}) {
        display: none;
    }
}

`;

type Props = {
    teamId: string;
    next: TeamEvent | undefined;
    events: TeamEvent[];
}

export function DesktopScheduleView({ teamId, next, events }: Props) {
    
    const columns: TableColumn[] = useScheduleTableColumns(teamId, events);
    const showNextGame = next && !SHANE_SAID_REMOVE_NEXT

    return <>
        {showNextGame && <ScheduledGameHeader
            date={dayjs(new Date(next.ScheduledStartTime.Time.seconds * 1000))}
            home={{
                id: next.home.id.toString(),
                title: next.home.title,
                logo: next.home.logo || undefined,
                record: next.home.record && (next.home.record.wins + "-" + next.home.record.losses + "-" + next.home.record.ties) || "0 - 0 - 0",
            }}
            visitor={{
                id: next.visitor.id.toString(),
                title: next.visitor.title,
                logo: next.visitor.logo || undefined,
                record: next.visitor.record && (next.visitor.record.wins + "-" + next.visitor.record.losses + "-" + next.visitor.record.ties) || "0 - 0 - 0",
            }}
            {...{next}}
        />}

        <StyledTeamSchedule>
            <Table
                defaultSort={{
                    key: "date",
                    direction: SortOrder.Asc,
                }}
                columns={columns}
                removeColumnHeaderBorder
                underlineTableHeaders
                smallTableHeaders
                boldTableHeaders
                hyperLinkTextDecorationStyle="solid"
                controlsAtBottom
                allowRowHighlighting
            />
        </StyledTeamSchedule>
    </>;

}
