// helpers
import { translatePosition, translateDuty } from "../../libs/translatePosition";

// imports

import { firebaseUserState } from "../user/useSetFirebaseAuth";
import { firestore } from "../../libs/firebase";
import { useRecoilValue, useRecoilState } from "recoil";

import { To, useLocation, useNavigate } from "react-router-dom";
import { useDeviceState } from "./useDeviceState";
import { NavigationItem } from "../../components/NavButton";
import { MouseEvent, useCallback, useEffect, useMemo } from "react";
import { useEventsState } from "./useEventsStats";
import { AppStateInterface } from "./app.interface";
import { AppUiLocations, appState } from "./app.state";
import { User } from "firebase/auth";
import { useConfig } from "../../config/useConfig";
import { useNestedApp } from "./useNestedApp";

export function useAppState(): AppStateInterface {

    const [ state, setAppState ] = useRecoilState(appState)
    
    const _navigate = useNavigate()
    const location = useLocation()
    const device = useDeviceState()
    const events = useEventsState()
    const config = useConfig()
    const firebaseUser = useRecoilValue(firebaseUserState)

    // nested apps
    const ClassicDashboard = useNestedApp("classic-dashboard")
    const StatsWidget = useNestedApp("stats-widget")

    useEffect(() => {
        
        // on signout, clear the selected team 
        const removeUserSignoutEvent = events.add("user:signout", (user:User) => {
            setAppState(state => ({...state, selectedTeam: undefined}))
        })

        // unsubscribe
        return () => removeUserSignoutEvent()

    }, [])

    // determine if the menu if open or not
    const menu = useMemo(() => ({
        ...state.menu,
        open: () => setAppState(state => ({...state, menu: {isOpen: true}})),
        close: () => setAppState(state => ({...state, menu: {isOpen: false}})),
    }), [ state.menu ])

    // close the menus when we navigate
    const navigate = useCallback((to:To, app?:string) => {
        
        menu.close()
        events.trigger('close-more-menu')
        
        switch(app){
            default: return _navigate(to)
            case "classic-dashboard": ClassicDashboard.navigate(to.toString()); break;
            case "stats-widget": StatsWidget.navigate(to.toString()); break;
        }

    }, [ events ])

    const navigateOnClick = useCallback((path:string, ...callbacks:Array<() => void>) => {
        return (event:MouseEvent<HTMLElement>) => {
            event.preventDefault()
            navigate(path)
            callbacks.forEach(callback => callback())
        }
    }, [ navigate ])

    // loading controls
    const loading = {
        complete: (key:string) => setAppState(state => ({...state, loaded: [...state.loaded, key]})),
        isComplete: (...keys:string[]) => keys.every(key => state.loaded.includes(key)),
    }

    // helpers list
    const helpers = {
        translatePosition,
        translateDuty
    }

    // navigation controls
    const navigation = {
        set( items:NavigationItem[] ){
            setAppState(state => ({...state, navigation: items}))
        },
        add( item:NavigationItem ){
            setAppState(state => ({...state, navigation: [...state.navigation, item]}))
        },
        remove( item:NavigationItem ){
            setAppState(state => ({...state, navigation: state.navigation.filter(i => i !== item)}))
        },
        items(){
            return state.navigation
        },
        leftMobileItems(){
            return state.navigation.filter(item => item.mobile === 'left')
        },
        rightMobileItems(){
            return state.navigation.filter(item => item.mobile === 'right')
        },
        iconMobileItem(){
            return state.navigation.find(item => item.mobile === 'icon')
        },
        desktopItems(){
            return state.navigation.filter(item => item.desktop)
        }
    }

    // ui controls
    const ui = (name:AppUiLocations) => ({
        set: (content:JSX.Element) => setAppState(state => ({...state, ui: {...state.ui, [name]: content}})),
        get: () => state.ui[name],
        clear: () => setAppState(state => ({...state, ui: {...state.ui, [name]: undefined}})), 
    })

    return {
        
        ...state,
        location,
        isLoggedin: !!firebaseUser,
        hasSelectedTeam: !!state.selectedTeam,
        selectedTeam: state.selectedTeam || "",

        layout: {
            showDesktop: device.isDesktop || device.isTablet,
            showMobile: device.isMobile,
        },

        config,
        activeUser: firebaseUser,
        connections: { firestore },
        loading,
        menu,
        helpers,
        navigation,

        events,
        device,

        registerNestedApp: (name:string, app:ReturnType<typeof useNestedApp>) => setAppState(state => ({...state, nestedApps: {...state.nestedApps, [name]: app}})),
        getApp: (name:string) => state.nestedApps[name],
        setSelectedTeam: (teamId:string) => {
            setAppState(state => ({...state, selectedTeam: teamId}))
            navigate("/games")
        },
        navigate,
        navigateOnClick,
        ui,
        
    }

}