import { Config } from "./type";

export const config:Config = {
    name: "",
    settings: {
        showDumpStateButton: false,
    },
    features: {

    },
    apps: {
        statsWidget: "https://gamesheetstats.com",
        leagueDashboard: "https://gamesheet.app",
    },
    gateways: {
        data: "/gateway/data",
        events: "/gateway/events",
        auth: "/gateway/auth",
    },
    services: {
        assetsApi: "/service/assets",
        registryApi: "/service/registrysync",
        scoresheetExportApi: "/service/scoresheets",
    },
    firebase: {}
}