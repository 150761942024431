export function translatePosition(position: string, returnTitle = false): string {
    switch (position?.toLowerCase()) {
        case "goalie":
            return returnTitle ? "Goalie" : "G";
        case "forward":
            return returnTitle ? "Forward" : "F";
        case "centre":
        case "center":
            return returnTitle ? "Center" : "C";
        case "defence":
        case "defense":
            return returnTitle ? "Defense" : "D";
        case "left_wing":
            return returnTitle ? "Left Wing" : "LW";
        case "transition":
            return returnTitle ? "Transition" : "T";
        case "right_wing":
            return returnTitle ? "Right Wing" : "RW";
        case "head_coach":
            return returnTitle ? "Head Coach" : "HC";
        case "assistant_coach":
            return returnTitle ? "Assistant Coach" : "AC";
        default:
            return "-";
    }
};

export function translateDuty(position: string, returnTitle = false): string {
    switch (position?.toLowerCase()) {
        case "captain":
            return returnTitle ? "Captain" : "C";
        case "alternate_captain":
            return returnTitle ? "Alternate Captain" : "A";
        default:
            return "";
    }
};