import { Button, Title } from "@gamesheet/ui";
import * as Grid from "../layouts/GridItems";
import { useAppState } from "../state/app/useAppState";
import PlayerForm from "../components/PlayerForm";
import { useEffect, useState, useCallback } from "react";
import { usePlayerData } from "../state/data/usePlayerData";
import { useParams } from "react-router";
import { PageLoading } from "../components/PageLoading";

export function TeamRosterPlayerEdit(){

    const app = useAppState()
    const params = useParams()
    const playerId = params.id
    const isNew = playerId == "new"
    const { formData, setFormData, submit } = usePlayerData(playerId)
    const [ loading, setLoading ] = useState(false)

    const onSavePlayer = useCallback( () => {
        setLoading(true)
        submit(onPlayerSaved)
    }, [ submit ])

    const onPlayerSaved = () => {
        setLoading(false)
        app.navigate(`/roster/team`)
    }
    
    useEffect(() => {
        app.ui('leftTray').set(<Button gs-style="solid" onClick={app.navigateOnClick(`/roster/team`)}>Back</Button>)
        app.ui('rightTray').set(<Button gs-style="solid" onClick={onSavePlayer}>{ playerId == "new" ? "Create" : "Save" }</Button>)

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [ onSavePlayer ])

    if(loading){
        return <PageLoading />
    }

    return (<>
        <Grid.Blank column={1} row={1}>
            
            <PlayerForm formData={formData} setFormData={setFormData} />
            { !isNew && <Button variant="danger" width="block" onClick={app.navigateOnClick(`/roster/player/${playerId}/delete`)}>Remove {formData.firstName} {formData.lastName}</Button> }
        </Grid.Blank>
    </>)
}