// CoachForm.tsx
import { FormWrapper, Title } from "@gamesheet/ui";
import "@gamesheet/ui/build/index.css";
import { CoachFormData } from "../state/data/useCoachData";

type CoachFormProps = {
  formData: CoachFormData;
  setFormData: React.Dispatch<React.SetStateAction<CoachFormData>>;
};

const CoachForm = ({ formData, setFormData }:CoachFormProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //TODO list: Convert birthdate to correct format for db
  // Stylign for birthday section
  // Add validation for required fields
  // Jersey number comparison across team
  // Status value is a string here but it should be a boolean in the DB

  return (
    <FormWrapper>
      <fieldset>
        <div className="form-floating mb-3">
          <input type="text" name="firstName" id="firstName" className="form-control" placeholder="First Name" onChange={handleChange} value={formData.firstName} />
          <label htmlFor="firstName" className="floating">
            First Name
          </label>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="lastName" id="lastName" className="form-control" placeholder="Last Name" onChange={handleChange} value={formData.lastName} />
          <label htmlFor="lastName" className="floating">
            Last Name
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="position" id="position" className="form-select" onChange={handleChange} value={formData.position}>
            <option value="">None</option>
            <option value="head_coach">Head Coach</option>
            <option value="assistant_coach">Assistant Coach</option>
            <option value="trainer">Trainer</option>
            <option value="assistant_trainer">Assistant Trainer</option>
            <option value="manager">Manager</option>
            <option value="head_coach_at_large">Head Coach At Large</option>
            <option value="assistant_coach_at_large">Assistant Coach At Large</option>
            <option value="trainer_at_large">Trainer At Large</option>
          </select>

          <label htmlFor="position" className="floating">
            Position
          </label>
        </div>

      </fieldset>
    </FormWrapper>
  );
};

export default CoachForm;
