import { addImgSizeToUrl } from "../../libs/addImgSizeToUrl";
import defaultHomeJersey from "../../assets/images/home_jersey.svg";
import defaultVisitorJersey from "../../assets/images/visitor_jersey.svg";
import { EventToFinalScore } from "../../libs/eventToWinLossScore";
import { TableColumn } from "./TableColumn";
import { TeamEvent } from "../../state/data/useTeamEvents";
import dayjs from "dayjs";
import styled from "styled-components";
import { useAppState } from "../../state/app/useAppState";

export const Logo = styled.img`
width: 32px;
height: 32px;
`

type MapDataFunction = (label: any, row?: { [key:string]:any }) => JSX.Element | string;
type ClickableRowFunction = (func: MapDataFunction) => MapDataFunction;

export function useScheduleTableColumns(teamId: string, events: TeamEvent[] ): TableColumn[] {
    
    const app = useAppState()

    const ClickableRow:ClickableRowFunction = (func: MapDataFunction) => (data, row) => {
        if (row?.details?.season?.id !== undefined && row?.details?.id !== undefined){
            return(
                <div onClick={() => app.navigate(`/games/${row?.details?.season?.id}/${row?.details?.id}`)} className="game-link">
                    {func(data, row)}
                </div>
        )}
        else return func(data, row)
    }

    return [
        { 
            key: "date", 
            isFixed: true, 
            data: events.map((event) => (event.ScheduledStartTime?.Time.seconds || 0) * 1000), 
            label: "Date", 
            mapData: ClickableRow(data => dayjs(new Date(data)).format("ddd, MMM D")),
        },
        { 
            key: "visitor",
            data: events.map((event) => event.visitor?.title || "TBD"),
            label: "Visitor",
            mapData: ClickableRow(data => data),
        },
        { 
            key: "visitor_logo", 
            data: events.map((event) => event.visitor?.logo ? addImgSizeToUrl(event.visitor.logo, 256) : defaultVisitorJersey), 
            label: "Logo", 
            mapData: ClickableRow(data => <Logo src={data} className="logo" alt="visitor logo" />)
        },
        { 
            key: "details", 
            data: events, 
            label: "Details", 
            mapData: ClickableRow(data => {
                
                if(data.status == "scheduled"){
                    return dayjs(new Date(data.ScheduledStartTime?.Time.seconds * 1000)).format("H:mm a");
                }
                
                const score = EventToFinalScore(data, teamId)
                return `${score.result} ${score.visitorTeamScore} - ${score.homeTeamScore}`

            })
            
        },
        { 
            key: "home_logo", 
            data: events.map((event) => event.home?.logo ? addImgSizeToUrl(event.home.logo, 256) : defaultHomeJersey), 
            label: "Logo", 
            mapData: ClickableRow(data => <Logo src={data} className="logo" alt="home logo" /> )
        },
        { 
            key: "home", 
            data: events.map((event) => event.home?.title || "TBD"), 
            label: "Home",
            mapData: ClickableRow(data => data),
        },
        { 
            key: "location", 
            data: events.map((event) => event.location), 
            label: "Location",
            mapData: ClickableRow(data => data),
        },
    ]

}