import { RosterProps } from "."
import { Title } from "@gamesheet/ui";
import { EntityCard } from "../../components/EntityCard";
import * as Grid from "../../layouts/GridItems";
import { useEffect } from "react";
import Avatar from "../../assets/images/blank_avatar.svg"
import App from "../../App";
import { useAppState } from "../../state/app/useAppState";

type VerticalRosterProps = Omit<RosterProps, "layout">;

export function VerticalRoster({ roster, onClickCoach=()=>{}, onClickPlayer=()=>{} }:VerticalRosterProps){

    const app = useAppState()

    return <>
            
        <Grid.Blank column={1} row={1}>
            <Title bartype="divider" text="Players" size="h2"/>
        </Grid.Blank> 

        
        { roster.skaters.map((player, index) => {
            
            const jersey = player.jersey ? `#${player.jersey}` : "";
            return <Grid.Blank key={index} column={1} row={index+2} onClick={()=>onClickPlayer(player)}>
                    <EntityCard 
                        image={player.photo || Avatar} 
                        title={`${player.firstName} ${player.lastName}`}
                        subtitle={app.helpers.translatePosition(player.position, true)}
                        stat={jersey}
                        substat={app.helpers.translateDuty(player.duty, true)}
                    />
                </Grid.Blank>
            })
        }

        <Grid.Blank column={1} row={roster.skaters.length+2}>
            <Title bartype="divider" text="Goalies" size="h2"/>
        </Grid.Blank>

        { roster.goalies.map((player, index) => {
            
            const jersey = player.jersey ? `#${player.jersey}` : "";
            return <Grid.Blank key={index} column={1} row={index+3+roster.skaters.length} onClick={()=>onClickPlayer(player)}>
                    <EntityCard 
                        image={player.photo || Avatar} 
                        title={`${player.firstName} ${player.lastName}`}
                        subtitle={app.helpers.translatePosition(player.position, true)}
                        stat={jersey}
                        substat={app.helpers.translateDuty(player.duty, true)}
                    />
                </Grid.Blank>
            })
        }

        <Grid.Blank column={1} row={roster.skaters.length+roster.goalies.length+3}>
            <Title bartype="divider" text="Coaches" size="h2"/>
        </Grid.Blank> 
        
        { roster.coaches.map((coach, index) => {
            return <Grid.Blank key={index} column={1} row={index+roster.skaters.length+roster.goalies.length+4} onClick={()=>onClickCoach(coach)}>
                    <EntityCard 
                        title={`${coach.firstName} ${coach.lastName}`}
                        subtitle={app.helpers.translatePosition(coach.position, true)}
                    />
                </Grid.Blank>
            })
        }
    </>

}