import { Button } from "@gamesheet/ui";
import React, { MouseEvent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Roster } from "../views/Roster";
import { useAppState } from "../state/app/useAppState";
import { Iframe, useIframeLoadingState } from "../state/app/useIframeLoading";
import { useSeasonsData } from "../state/data/useSeasonTeams";
import { RosterCoach, RosterPlayer, useTeamRoster } from "../state/data/useTeamRoster";
import IframeWithOtp from "../components/IframeWithOtp";

const StyledFloatingMenu = styled.div`
display:flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
z-index: 1000;

.button {
    display: ilnine-block;
}
`

export function TeamRosterPage(){

    const app = useAppState()
    const roster = useTeamRoster()
    const seasonTeams = useSeasonsData()
    const navTeam = seasonTeams.unlocked || seasonTeams.teams[0]
    
    const onClickCoach = (coach: RosterCoach) => app.navigate(`/roster/coach/${coach.id}`)
    const onClickPlayer = (player: RosterPlayer) => app.navigate(`/roster/player/${player.id}`)

    const [ activeMenu, setActiveMenu ] = useState<ReactElement>()

    const close = () => {
        
        app.menu.close()
        
        // set menu
        setActiveMenu(<StyledFloatingMenu>
            <Button onClick={open}>Actions</Button>
        </StyledFloatingMenu>)
    }

    const open = () => {
        
        app.menu.open()

        // set menu
        setActiveMenu(<StyledFloatingMenu>
            <Button onClick={app.navigateOnClick('/roster/import/registry/usah', close)} variant="inverted">Import from USA Hockey</Button>
            <Button onClick={app.navigateOnClick('/roster/import/registry/hcr', close)} variant="inverted">Import from Hockey Canada</Button>
            <Button onClick={app.navigateOnClick('/roster/import/csv', close)} variant="inverted">Import from CSV</Button>
            <Button onClick={app.navigateOnClick('/roster/player/new', close)} variant="inverted">Add New Player</Button>
            <Button onClick={app.navigateOnClick('/roster/coach/new', close)} variant="inverted">Add New Coach</Button>
            <Button onClick={close}>Close</Button>
        </StyledFloatingMenu>)
    }

    useEffect(() => {

        activeMenu && app.ui('rightTray').set(activeMenu)

        return () => {
            app.ui('rightTray').clear()
        }

    }, [ activeMenu ])

    // start menu closed
    useEffect(() => {
        app.layout.showMobile && close()
    }, [])

    return <>
        { app.layout.showMobile && <Roster roster={roster} onClickCoach={onClickCoach} onClickPlayer={onClickPlayer} layout="vertical"/> }
        { app.layout.showDesktop && <IframeWithOtp id="team-roster" src={`${app.config.apps.leagueDashboard}/seasons/${navTeam.season.id}/teams/${navTeam.id}/roster/players`}  /> }
    </>

}