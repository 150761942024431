import { useEffect, useState } from "react";
import { useUserState } from "../user/useUserState";
import { useAppState } from "../app/useAppState";
import { requestClient, requestId, xGameSheetHeaders } from "../../libs/xGameSheetHeaders";

export function useInvitationCode() {

    const app = useAppState();
    const user = useUserState();
    const [invitationCode, setInvitationCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (invitationCode) {
            setError("");
        }
    }, [invitationCode]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInvitationCode(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        if (!invitationCode) {
            setError("Please enter a valid invitation code");
            return;
        }

        submit();

    };

    const submit = async () => {

        try {

            // set submitting state
            setIsSubmitting(true);
            const staticRequestId = requestId();

            // send request
            await fetch(`${app.config.gateways.events}/invitations`, {
                method: "POST",
                headers: {
                    ...xGameSheetHeaders(staticRequestId),
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.tokens.access}`
                },
                body: JSON.stringify({
                    event: "invite-event",
                    attributes: {
                        schema: "accept-invite-event",
                        requestId: staticRequestId,
                        clientId: requestClient(),
                    },
                    data: {
                        code: invitationCode,
                    },
                }),
            });

            // expire user claims to force new claims to be generated
            user.Expire()
            setInvitationCode("");

        } catch (error) {

            console.log(error);
            setIsSubmitting(false);

        }

    }

    return {

        // value
        value: invitationCode,
        set: setInvitationCode,
        handleChange,

        // submission
        submit,
        handleSubmit,
        error,
        isSubmitting,

    }

}