type ScoreboardPeriod = {
    [key:string]:number
}

export function EventToFinalScore(event:any, teamId:string) {

    const { home, visitor } = event
    if( !home || !visitor ) return {
        result: "N/A",
        visitorTeamScore: 0,
        homeTeamScore: 0
    }

    const { team, opponent } = home?.prototeam?.id === teamId ? { opponent: visitor, team: home } : { opponent: home, team: visitor }

    function getScore(team:any){
        return team.scoreboard.find((period:ScoreboardPeriod) => !!period['final'])?.final || 0
    }

    const teamScore = getScore(team);
    const opponentScore = getScore(opponent);

    let result = teamScore > opponentScore ? "W" : teamScore < opponentScore ? "L" : "T"

    if (event.hasOvertime && !event.hasShootout) {
        result = `OT${result}`
    }

    if (event.hasShootout) {
        result = `SO${result}`
    }

    return {
        result,
        visitorTeamScore: getScore(visitor),
        homeTeamScore: getScore(home)
    }

}