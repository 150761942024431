import { Button, Title } from "@gamesheet/ui";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import CoachForm from "../components/CoachForm";
import { PageLoading } from "../components/PageLoading";
import * as Grid from "../layouts/GridItems";
import { useAppState } from "../state/app/useAppState";
import { useCoachData } from "../state/data/useCoachData";

export function TeamRosterCoachEdit(){
    
    const app = useAppState()
    const params = useParams()
    const coachId = params.id
    const isNew = coachId == "new"
    const { formData, setFormData, submit } = useCoachData(coachId)
    const [ loading, setLoading ] = useState(false)
    const onSaveCoach = useCallback( () => {
        setLoading(true)
        submit(onCoachSaved)
    }, [submit])

    const onCoachSaved = () => {
        setLoading(false)
        app.navigate(`/roster/team`)
    }
    
    useEffect(() => {
        
        app.ui('leftTray').set(<Button gs-style="solid" onClick={() => app.navigate(`/roster/team`)}>Back</Button>)
        app.ui('rightTray').set(<Button gs-style="solid" onClick={onSaveCoach}>{ coachId == "new" ? "Create" : "Save" }</Button>)

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [onSaveCoach])

    if(loading){
        return <PageLoading />
    }
    
    return (<>
        <Grid.Blank column={1} row={1}>
            
            <CoachForm formData={formData} setFormData={setFormData} />
            { !isNew && <Button variant="danger" width="block" onClick={app.navigateOnClick(`/roster/coach/${coachId}/delete`)}>Remove {formData.firstName} {formData.lastName}</Button> }
        
        </Grid.Blank>
    </>)
}