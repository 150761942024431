import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/fonts/fonts.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot, useRecoilCallback } from 'recoil';
import { GameSheetTheme } from '@gamesheet/ui';
import { GameSheetRouter } from './Router';
import TagManager from "react-gtm-module"
import config from './config/index';
import { DebugButton } from './components/DumpState';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: 'GTM-PDKWKG8'
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <GameSheetTheme theme={{
      primaryColor: '#FEC307',
      secondaryColor: 'rgb(54, 56, 61)',
      textOnPrimaryColor: 'rgb(54, 56, 61)',
      textOnSecondaryColor: '#ffffff',
      success: '#28a745',
      info: '#274be7',
      danger: "#dc3545"
    }}>
      <RecoilRoot>
        <GameSheetRouter />
        { config.settings.showDumpStateButton && <DebugButton /> }
      </RecoilRoot>
    </GameSheetTheme>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
