import { createBrowserRouter } from "react-router-dom"
import App from "../../App";
import { ExternalContentLayout } from "../../layouts/ExternalContentLayout";
import { MainLayout } from "../../layouts/MainLayout";
import { HelpAndSupportPage } from "../../pages/help";
import { EditTeamPage } from "../../pages/team.edit";
import { JoinSeasonPage } from "../../pages/season.join";
import { TeamSettingsPage } from "../../pages/team.settings";
import { RosterPage } from "../../pages/roster";
import { TeamSchedulePage } from "../../pages/team.schedule";
import { SchedulePageBoxScore } from "../../pages/game.boxscore";
import { TeamStatsPage } from "../../pages/team.stats";
import { TeamRosterPage } from "../../pages/team.roster";
import { SeasonsRostersPage } from "../../pages/roster.seasons";
import { SeasonRosterPage } from "../../pages/roster.season";
import { TeamRosterPlayerEdit } from "../../pages/roster.team.player.edit";
import { TeamRosterCoachEdit } from "../../pages/roster.team.coach.edit";
import { Chat } from "../../pages/chat";
import { TeamsApp } from "./App";
import { ImportFromRegistry } from "../../pages/roster.importFromRegistry";
import { ImportFromCsv } from "../../pages/roster.importFromCsv";
import { TeamRosterPlayerDelete } from "../../pages/roster.team.player.delete";
import { TeamRosterCoachDelete } from "../../pages/roster.team.coach.delete";
import { useDeviceState } from "../../state/app/useDeviceState";
import { LegacyProfilePage } from "../../pages/user.profile.legacy";
import { InvitationPage } from "../../pages/user.invitation";
import { LegacyNatificationsPage } from "../../pages/user.notifications.legacy";

export const router = (device:ReturnType<typeof useDeviceState>) => createBrowserRouter([
    { 
        path: "/", 
        element: <App />,
        children: [
            {
                element: <TeamsApp />,
                children: [
                    {
                        element: device.isDesktop || device.isTablet ? <ExternalContentLayout /> : <MainLayout />,
                        children: [{
                            path: "/roster", 
                            element: <RosterPage />,
                            children: [
                                { path: "team", element: <TeamRosterPage /> },
                            ]
                        }]
                    },
                    { 
                        element: <MainLayout />,
                        children: [
                            { path: "/", element: <TeamSchedulePage /> },
                            { 
                                path: "roster", 
                                element: <RosterPage />,
                                children: [
                                    { path: "team", element: <TeamRosterPage /> },
                                    { path: "coach/:id", element: <TeamRosterCoachEdit /> },
                                    { path: "coach/:id/delete", element: <TeamRosterCoachDelete /> },
                                    { path: "player/:id", element: <TeamRosterPlayerEdit /> },
                                    { path: "player/:id/delete", element: <TeamRosterPlayerDelete /> },
                                    { path: "seasons", element: <SeasonsRostersPage /> },
                                    { path: "seasons/:seasonId", element: <SeasonRosterPage /> },
                                    { path: "import/registry/:registry", element: <ImportFromRegistry /> },
                                    
                                ]
                            },
                            { path: "/chat", element: <Chat /> },
                            { path: "games", element: <TeamSchedulePage /> },
                            { path: "more/team/settings", element: <TeamSettingsPage /> },
                            { path: "more/team/join", element: <JoinSeasonPage /> },
                            { path: "more/team/edit", element: <EditTeamPage /> },
                            { path: "more/myaccount/invitation", element: <InvitationPage /> },
                        ]
                    },{
                        element: <ExternalContentLayout isActionBarOpen/>,
                        children: [
                            { path: "seasons", element: <TeamStatsPage /> },
                        ]
                    },{
                        element: <ExternalContentLayout />,
                        children: [
                            { path: "games/:seasonId/:gameId", element: <SchedulePageBoxScore /> },
                            { path: "roster/import/csv", element: <ImportFromCsv /> },
                            { path: "more/myaccount/profile", element: <LegacyProfilePage /> },
                            { path: "more/myaccount/notifications", element: <LegacyNatificationsPage /> },
                        ]
                    },{
                        element: <ExternalContentLayout hideUserBar/>,
                        children: [
                            { path: "more/help", element: <HelpAndSupportPage /> },
                        ]
                    }
                ]
            }
        ]
    }
]);   