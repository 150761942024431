import styled from "styled-components"

import SelectionArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import AddNewTeam from '@mui/icons-material/Add'
import { useSelectedTeamState } from "../state/team/useSelectedTeamState";
import { useAppState } from "../state/app/useAppState";
import { useDeviceState } from "../state/app/useDeviceState";
import { useParams } from "react-router-dom";

import logoPlaceholder from "../assets/images/blank_jersey.svg"
import { NavButton } from "../components/NavButton";
import { useRef, useState } from "react";
import { useTeamsData } from "../state/data/useTeams";
import { Button, Logo } from "@gamesheet/ui";
import { useSeasonsData } from "../state/data/useSeasonTeams";

const StyledMobileUserBar = styled.div.attrs({
    className: "user-bar"
})`
display: flex;
align-items: center;
width: 100vw;
height: 60px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 3000;

.logo-tabs {
    display: none;
}
`

const StyledDesktopUserBar = styled.div.attrs({
    className: "user-bar"
})`
width: 100vw;
margin-top: 32px;
height: 78px;
background-color: ${props => props.theme.secondaryColor};
color: ${props => props.theme.textOnSecondaryColor};
position: fixed;
top: 0;
left: 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
z-index: 100;

.logo-tabs {
    display: flex;
}

.selector {
    padding-left: 20px;
}

.inner-user-bar {
    max-width: 1280px;
    margin: 0 auto;
}
`

const InnerUserBar = styled.div.attrs({
    className: "inner-user-bar"
})`
width: 100vw;
display: flex;
justify-content: flex-start;
height: 78px;
`


const TeamSelector = styled.div.attrs({
    className: "team-selector"
})<{ stretch?: boolean }>`
display: flex;
align-items: center;
justify-content: space-between;
gap: 15px;
width: ${p => p.stretch ? "100%" : "auto"};


border-radius: 4px;
padding-right: 8px;
cursor: pointer;
user-select: none;

small {
    opacity: 0.6;
}
`

const TeamLogo = styled.div.attrs({
    className: "team-logo"
})`
align-items: center;
padding-left: 15px;

img {
    display: block;
    height: 40px;
}
`
const TeamTitle = styled.div`
flex-basis: 100%;
flex: 1;
`

const Selector = styled.div.attrs({
    className: "selector"
}) <{ state: string }>`
min-width: 40px;
cursor: pointer;

svg {
    transform: ${props => props.state === "open" ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 0.2s ease-in-out;
}
`

const Tab = styled.button.attrs({
    className: "tab"
})`
min-width: 60px;
width: 80px;
text-align: center;
font-size: 0.8rem;
z-index: 75;
background-color: transparent;
border: none;

a { 
    color: ${props => props.theme.textOnSecondaryColor};
    text-decoration: none;
}

a.active, a:hover { { 
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
}
`

const Tabs = styled.div.attrs({
    className: "tabs"
})`
display: flex;
align-items: center;
z-index: 75;
`

const StyledPrototeamSelection = styled.div.attrs({
    className: "prototeam-selection"
}) <{ state: string, desktopWidth?: number }>`
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: flex-start;
gap: 15px;

background-color: ${props => props.theme.secondaryColor};
bottom: ${props => props.state === "open" ? "0" : "100vh"};
position: fixed;
transition: bottom 0.2s ease-in-out;
z-index: 2000;

padding: 15px;

.team-selector {
    cursor: pointer;
    background-color: #FFF;
    border-radius: 4px;
    padding: 15px;
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 15px;

    .team-logo {
        padding: 0;
        place-self: center center;
    }
}
`

const DesktopPrototeamSelection = styled(StyledPrototeamSelection)`
left: calc((100vw - min(1280px, 100vw)) / 2);
height: calc(100vh - 110px);
width: ${props => props.desktopWidth ? props.desktopWidth : "425px"};
max-width: 425px;
`

const MobilePrototeamSelection = styled(StyledPrototeamSelection)`
height: calc(100vh - 60px);
width: 100vw;
`



export function UserBar() {

    const app = useAppState()
    const selectedTeam = useSelectedTeamState()
    const StyledUserBar = app.device.isMobile ? StyledMobileUserBar : StyledDesktopUserBar;
    const [selectorState, setSelectorState] = useState<string>("closed");
    const { teams } = useTeamsData()
    const data = useSeasonsData()
    const teamSelectorRef = useRef<HTMLDivElement>(null)
    const PrototeamSelection = app.layout.showMobile ? MobilePrototeamSelection : DesktopPrototeamSelection;

    const buttons = app.navigation.desktopItems();

    const GetPlaysIn = (teamId: string) => {
        
        const seasonTeams = data.prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams

        if( seasonTeams?.length ){
            
            for(let i = seasonTeams.length - 1; i >= 0; i--){
                try {
                    const season = seasonTeams[i].season.title
                    const division = seasonTeams[i].division.title
                    return `${division}, ${season}`
                } catch (e) {
                    continue;
                }
            }

        }

        return null
    }

    const toggleSelector = () => {
        selectorState == "closed" ? app.menu.open() : app.menu.close();
        setSelectorState((current) => current === "closed" ? "open" : "closed")
    }

    const selectTeam = (id: string) => {
        app.menu.close();
        app.setSelectedTeam(id);
        setSelectorState("closed")
    }

    return <>
        <StyledUserBar>
            
            <InnerUserBar>

                <TeamSelector stretch={app.device.isMobile} ref={teamSelectorRef} onClick={toggleSelector}>
                    <TeamLogo>
                        <img src={selectedTeam?.logo ? `${selectedTeam?.logo}/256` : logoPlaceholder} />
                    </TeamLogo>
                    <TeamTitle>
                        {selectedTeam?.title}<br />
                        <small>{selectedTeam?.club}</small>
                    </TeamTitle>
                    <Selector state={selectorState}>
                        <SelectionArrowIcon />
                    </Selector>
                </TeamSelector>

                {app.layout.showDesktop && <>
                    <div className="logo-tabs">
                        <Tabs>
                            {buttons.map((button, index) => (
                                <Tab key={index}>
                                    <NavButton button={button} />
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </>}


            </InnerUserBar>

        </StyledUserBar>

        <PrototeamSelection state={selectorState} desktopWidth={teamSelectorRef.current?.clientWidth}>
            {teams.map((team, index) => (
                <TeamSelector key={index} onClick={() => selectTeam(team.id)}>
                    <TeamLogo>
                        <img src={team.logo ? `${team.logo}/256` : logoPlaceholder} />
                    </TeamLogo>
                    <TeamTitle>
                        {team?.title}<br />
                        <small><i>Plays In {GetPlaysIn(team.id)}</i></small>
                    </TeamTitle>
                </TeamSelector>
            ))}
            <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                app.navigate("more/myaccount/invitation")
                setSelectorState("closed")
            }}>
                <AddNewTeam fontSize="small"/>
                <span style={{ fontSize:'16px' }}>Add New Team</span>
            </Button>
        </PrototeamSelection>

    </>
}