import { createBrowserRouter } from "react-router-dom"
import App from "../../App";
import { ExternalContentLayout } from "../../layouts/ExternalContentLayout";
import { LeagueAppHomePage } from "../../pages/legacy.dashboard";
import { SeasonStatsPage } from "../../pages/season.stats";
import { LeaguesApp } from "./App";
import { useDeviceState } from "../../state/app/useDeviceState";

export const router = (device:ReturnType<typeof useDeviceState>) => createBrowserRouter([
    { 
        path: "/", 
        element: <App />, 
        children: [
            {
                element: <LeaguesApp />,
                children: [
                    {
                        element: <ExternalContentLayout />,
                        children: [
                            { path: "/seasons/:seasonId/stats", element: <SeasonStatsPage /> },
                        ]
                    },
                    {
                        element: <ExternalContentLayout />,
                        children: [
                            { path: "/seasons/:seasonId/*?", element: <LeagueAppHomePage /> },
                            { path: "*?", element: <LeagueAppHomePage /> },
                        ]
                    }
                ]
            }
            // { 
            //     element: <MainLayout />,
            //     children: [
            //         { path: "/seasons/:seasonId/teams", element: <SeasonTeamsPage /> },
            //         { path: "/seasons/:seasonId/teams/:teamId*", element: <SeasonTeamPage /> },
            //         { path: "/seasons/:seasonId/divisions*", element: <SeasonDivisionsPage /> },
            //     ]
            // },
            
        ]
    }
]);   