import { Title } from "@gamesheet/ui";
import styled from "styled-components";
import { TeamEvent } from "../../state/data/useTeamEvents";
import { GameCard } from "../../components/GameCard";
import { useAppState } from "../../state/app/useAppState";

const StyledSmallEventsGrid = styled.div`
display: flex;
flex-direction: column;
`

const StyledPastEventsGrid = styled(StyledSmallEventsGrid)`
row-gap: 0;

.game-card-list-final:nth-child(odd) {
    background-color: #EEE;
}
`

const StyledFutureEventsGrid = styled(StyledSmallEventsGrid)`
padding-top: 1rem;
row-gap: 1rem;
`

type Props = {
    todayRef: React.RefObject<HTMLDivElement>;
    next: TeamEvent | undefined;
    scheduledGames: TeamEvent[];
    completedGames: TeamEvent[];
}

export function MobileScheduleView({ todayRef, next, scheduledGames, completedGames }:Props){

    const app = useAppState();

    return (<StyledSmallEventsGrid>
        { !!next && <GameCard game={next} teamId={app.selectedTeam} template="featured" /> }
        <Title text="Schedule" bartype="bar" variant="inverted" />
        <StyledPastEventsGrid>
            { completedGames.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-final" /> )}
        </StyledPastEventsGrid>
        <div ref={todayRef} />
        <StyledFutureEventsGrid>
            { scheduledGames.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-schedule" /> )}
        </StyledFutureEventsGrid>
    </StyledSmallEventsGrid>)

}