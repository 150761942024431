import { useAppState } from "../state/app/useAppState";
import { useRef } from "react";
import { PageLoading } from "../components/PageLoading";
import { useTeamEventsData } from "../state/data/useTeamEvents";
import styled from "styled-components";
import { MobileScheduleView } from "../views/Schedule/MobileScheduleView";
import { DesktopScheduleView } from "../views/Schedule/DesktopScheduleView";
import { Alert } from "@gamesheet/ui";

const StyledSmallEventsGrid = styled.div`
display: flex;
flex-direction: column;
`

export function TeamSchedulePage(){
    
    const app = useAppState();
    const todayRef = useRef<HTMLDivElement|null>(null)
    const events = useTeamEventsData()
    const next = events.nextGame

    const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')

    // wait for events to load
    if(showLoading){
        return <PageLoading />
    }

    if(!events.games.length){
        return <Alert message="There are no events scheduled for this team" style="warning" />
    }

    if(app.layout.showMobile){
        return <MobileScheduleView todayRef={todayRef} next={next} scheduledGames={events.scheduledGames()} completedGames={events.completedGames()} />
    }

    if(app.layout.showDesktop){
        return <DesktopScheduleView next={next} events={events.all()} teamId={app.selectedTeam}/>
    }

    return <></>
}