import { Button } from "@gamesheet/ui";
import * as Grid from "../layouts/GridItems";
import { useAppState } from "../state/app/useAppState";
import { useEffect, useCallback } from "react";
import { usePlayerData } from "../state/data/usePlayerData";
import { useParams } from "react-router";
import { WarningCard } from "../components/EntityCard";


export function TeamRosterPlayerDelete(){

    const app = useAppState()
    const params = useParams()
    const playerId = params.id
    const { formData, remove } = usePlayerData(playerId)

    const onDeletePlayer = useCallback( () => {
        remove()
        app.navigate(`/roster/team`)
    }, [remove])
    
    useEffect(() => {
        
        app.ui('leftTray').set(<Button gs-style="solid" onClick={() => app.navigate(`/roster/player/${playerId}`)}>Cancel</Button>)
        app.ui('rightTray').set(<Button gs-style="solid" onClick={onDeletePlayer} variant="danger">Confirm Remove</Button>)

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [onDeletePlayer])

    return (<>
        <Grid.Blank column={1} row={1}>
            <WarningCard title={`Remove ${formData.firstName} ${formData.lastName}`}>
                This will remove the player {formData.firstName} {formData.lastName} from your roster. Are you sure?
            </WarningCard>  
        </Grid.Blank>
    </>)
}