// this should probably be imported from @gamesheet/ui

export type TableColumn = {
    key: string;
    sortDirection?: SortOrder;
    firstSort?: SortOrder.Asc | SortOrder.Desc
    isFixed?: boolean;
    alignLeft?: boolean;
    isNotSortable?: boolean;
    label: string;
    labelTip?: string;
    data?: any[];
    subData?: any[];
    tooltips?: string[];
    onHeaderClick?: (key: string, currentDirection: SortOrder, notSortable: boolean, firstSort: SortOrder) => any;
    mapData?: (label: any, row: {
        [key: string]: any;
    }) => any;
    mapSubData?: (label: any, row: {
        [key: string]: any;
    }) => any;
};

export enum SortOrder {
    Asc = "asc",
    Desc = "desc",
    None = "none"
}
