import { useAppState } from "../app/useAppState";
import { useTeamsData } from "../data/useTeams";

export function useSelectedTeamState(){

    const teams = useTeamsData();
    const app = useAppState();

    return teams.all().find(team => team.id === app.selectedTeam);



}