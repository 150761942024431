import { request } from "http"

type Header = { [key:string]:string }

export function requestId(): string {
    return Math.random().toString(36).substring(2, 15).toUpperCase()
}

export function requestClient(): string {
    return "team-dashboard"
}

export function xGameSheetHeaders(id?:string): Header {
    return {
        "X-GameSheet-Request-Id": id || requestId(),
        "X-GameSheet-Client-Id": requestClient()
    }
}